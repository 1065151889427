import React from "react";
import { Link } from "gatsby";
import { Divider, Link as StyledLink, Stack } from "@chakra-ui/layout";
import { BoxProps, Box } from "@chakra-ui/react";
import { Header } from "./header";
import { Contract } from "../pages/{ContractsJson.slug}";

interface Params extends BoxProps {
  page: Contract;
}

export function TOC({ page, ...rest }: Params) {
  if (!page.content.filter(({ name }) => name.startsWith("header")).length) {
    return null;
  }

  return (
    <Box {...rest}>
      <Header.H4>Pasqyra</Header.H4>
      <Divider my={5} />
      <Stack color="legit.600" ml={5} divider={<Divider />}>
        {page.content
          .filter(({ name }) => name.startsWith("header"))
          .map(({ params }, index) => {
            return (
              <StyledLink>
                <Link to={`#sec${index + 1}`}>
                  {index + 1}. {params.content}
                </Link>
              </StyledLink>
            );
          })}
      </Stack>
    </Box>
  );
}
