import { Divider, Stack } from "@chakra-ui/layout";
import { graphql, Link, useStaticQuery } from "gatsby";

export const InfoNavigation = () => {
  const data = useStaticQuery(graphql`
    query {
      allInfoJson(
        sort: { fields: title, order: ASC }
        filter: { slug: { ne: "/info/slug" } }
      ) {
        nodes {
          id
          title
          slug
        }
      }
    }
  `);

  const items = data.allInfoJson.nodes;

  return (
    <Stack divider={<Divider />}>
      {items.map((item) => (
        <Link to={item.slug}>{item.title}</Link>
      ))}
    </Stack>
  );
};
