import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Layout from "../layouts";
import { RenderComponents } from "../components";
import { Title } from "../components/title";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import {
  Box,
  Flex,
  Link as StyledLink,
  Text,
  Heading,
  Stack,
  Divider,
} from "@chakra-ui/layout";
import { useColorModeValue as mode } from "@chakra-ui/react";
// @ts-ignore
import * as styles from "../styles/ContractsJson.module.css";
import { ContractNavigation } from "../components/contract-navigation";
import { ContractCallToAction } from "../components/contract-call-to-action";
import { ContractBreadcrumb } from "../components/contract-breadcrumb";
import { InfoNavigation } from "../components/info-navigation";
import { Header } from "../components/header";
import { TOC } from "../components/toc";

export type Component = {
  type: string;
  name: string;
  params: { children?: string };
};

export type InfoPage = {
  name: string;
  title: string;
  slug: string;
  content: Array<Component>;
};

const InfoTemplate = (props) => {
  const info = props.data.infoJson;
  let sectionNumber = 0;

  return (
    <Layout location={props.location}>
      <Box my={10}>
        <Heading
          lineHeight={1.1}
          fontWeight={600}
          fontSize={{ base: "3xl", sm: "4xl", lg: "5xl" }}
        >
          <Text as={"span"}>{info.title}</Text>
        </Heading>

        <Box className={styles.flex}>
          <Box className={styles.content}>
            <RenderComponents
              items={info.content}
              modifyProps={(item) => {
                if (item.name.startsWith("header")) {
                  sectionNumber++;
                  return { id: `sec${sectionNumber}` };
                }
                return {};
              }}
            />
          </Box>
          <Box className={styles.aside}>
            <Box className={styles.navigation}>
              <TOC page={info} marginBottom={"35px"} />

              <Header.H4>Faqet e informacionit</Header.H4>
              <Divider my={5} />
              <InfoNavigation />
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default InfoTemplate;

export const pageQuery = graphql`
  query ($slug: String!) {
    infoJson(slug: { eq: $slug }) {
      id
      name
      title
      content {
        name
        type
        params {
          __typename
          title
          content
          contents
          caption
          list
          to
          label
          sourceLink
          table {
            body {
              value
              colSpan
              rowSpan
            }
            header {
              value
              colSpan
              rowSpan
            }
          }
        }
        __typename
      }
    }
  }
`;
